<template>
  <div id="Packs">
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0 font-size-125 font-family-krona">My Packs</h2>
              <span class="text-primary cursor-pointer" data-toggle="modal" data-target="#AddPack"><font-awesome-icon class="mr-2" :icon="['fas', 'plus']" />ADD PACK</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12" v-for="(budget, index) in packsList" v-bind:key="index">
        <div class="card mb-3 pack-item editable-widget">
          <div class="card-body pb-2">
            <div class="mb-3">
              <h3 class="h6 d-flex justify-content-between align-items-center pb-1">
                <div class="d-flex align-items-center">
                  <span>{{ budget.name }}</span>
                </div>
                <template v-if="budget.cryptocurrencies.length > 0">
                  <div class="white-space-nowrap">
                    <span v-if="budget.totalValue && budget.totalValue !== 0" class="mr-2" :class="[budget.totalValue - budget.invested_capital > 0 ? 'text-success' : 'text-danger']">${{ $fixPrice(budget.totalValue - budget.invested_capital, 2) }} (<span v-if="budget.totalValue - budget.invested_capital > 0">+</span>{{ $fixPrice((budget.totalValue * 100 / budget.invested_capital) - 100, 2) }}%)</span>
                    <span class="mr-2" v-else>-</span>

                    <span :id="'dropdownMenuButton'+index" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="ellipsis-icon px-2"><font-awesome-icon :icon="['fas', 'ellipsis-v']" /></span>
                    
                    <div class="dropdown-menu dropdown-menu-right py-0" :aria-labelledby="'dropdownMenuButton'+index">
                      <a href="javascript:void(0)" title="Add new crypto" class="dropdown-item border-bottom" data-toggle="modal" data-target="#AddCryptoToPack" @click="selectPack(budget.ID,budget.cryptocurrencies)"><span class="icon"><font-awesome-icon class="mr-2 text-primary" :icon="['fas', 'coins']" /></span>Add new crypto</a>
                      <a href="javascript:void(0)" title="Delete pack" class="dropdown-item" data-toggle="modal" data-target="#DeletePack" @click="selectPack(budget.ID,budget.cryptocurrencies)"><span class="icon"><font-awesome-icon class="mr-2 text-primary" :icon="['fas', 'times']" /></span>Delete pack</a>
                    </div>
                  </div>
                </template>
              </h3>
              <div class="">Invested Capital: ${{ $formatNumber(budget.invested_capital) }}</div>
            </div>
            <div class="overflow-x-auto">
              <table class="table mb-0" v-if="budget.cryptocurrencies.length > 0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col" class="white-space-nowrap">Purchase Price</th>
                    <th scope="col">Owned</th>
                    <th scope="col">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(crypto) in budget.cryptocurrencies" v-bind:key="crypto.id">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="mr-2">
                          <img class="float-left" width="20" :src="crypto.img"/>
                        </span> 
                        <strong>{{ crypto.symbol }}</strong>
                      </div>
                    </td>
                    <td>${{ crypto.purchase_price }}</td>
                    <td>{{ crypto.owned }}</td>
                    <td class="d-flex justify-content-between">
                      <span v-if="crypto.price" :class="[(crypto.price * crypto.owned) - (crypto.purchase_price * crypto.owned) > 0 ? 'text-success' : 'text-danger']">${{ $fixPrice((crypto.price * crypto.owned) - (crypto.purchase_price * crypto.owned), 2) }}</span>
                      <span v-else>-</span>
                      <div class="row-actions">
                        <a class="ml-3" href="javascript:void(0)" title="Delete crypto" data-toggle="modal" data-target="#DeleteCrypto" @click="selectPack(budget.ID);selectCrypto(crypto.id)"><font-awesome-icon :icon="['fas', 'times']" /></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            
              <div v-else class="mb-3 text-center">
                <a href="#" class="btn btn-outline-primary" data-toggle="modal" data-target="#AddCryptoToPack"  @click="selectPack(budget.ID,budget.cryptocurrencies)"><font-awesome-icon class="mr-2" :icon="['fas', 'plus']" /> Add cryptos to this pack</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="packsList.length <= 0 && loadingEnd" class="col-12">
        <div class="card mb-3 pack-item editable-widget text-center">
          <div class="card-body pb-2">
            <div class="alert alert-info">You haven't added packs yet. <a href="javascript:void(0)" data-toggle="modal" data-target="#AddPack">Add your first pack</a></div>
          </div>
        </div>
      </div>
    </div>
  <AddPack :id="'AddPack'" :modalTitle="'ADD PACK'" @packAdded="loadList()"></AddPack>
  <AddCryptoToPack :id="'AddCryptoToPack'" :modalTitle="'ADD CRYPTO TO PACK'" :idPack="selectedPack" :cryptocurrencies="cryptocurrenciesPackSelected" @cryptoAddedToPack="loadList()"></AddCryptoToPack>
  <DeletePack :id="'DeletePack'" :modalTitle="'DELETE PACK'" :idPack="selectedPack" @packDeleted="loadList()"></DeletePack>
  <DeleteCrypto :id="'DeleteCrypto'" :modalTitle="'DELETE Crypto'" :idPack="selectedPack" :idCrypto="selectedCrypto" @cryptoDeletedFromPack="loadList()"></DeleteCrypto>
  </div>
</template>

<script>
import axios from 'axios';
import AddPack from './templates/AddPack.vue'
import AddCryptoToPack from './templates/AddCryptoToPack.vue'
import DeletePack from './templates/DeletePack.vue'
import DeleteCrypto from './templates/DeleteCrypto.vue'

export default {
  name: 'Packs',
  data() {
    return {
      packsList: [],
      selectedPack: '',
      selectedCrypto: '',
      cryptocurrenciesPackSelected: [],
      loadigEnd: false
    };
  },
  created() {
    this.loadList();
  },
  components : {
    AddPack,
    AddCryptoToPack,
    DeletePack,
    DeleteCrypto
  },
  methods: {
    loadList(){
      axios.get(`/cryptos/packs`)
      .then((response)=> {
        this.packsList = response.data.body;
        this.loadingEnd = true;
        this.$emit('widgetLoaded');
        this.packsList.forEach(async (elem, index) =>{
          elem.totalValue = 0;
          for(let i=0; i < elem.cryptocurrencies.length; i++){
            this.$getSymbolPrice(elem.cryptocurrencies[i].symbol,elem.cryptocurrencies[i].source_price, index, elem.cryptocurrencies[i].id_coinmarketcap)
              .then((price) => {
                this.packsList[index].cryptocurrencies[i]['price'] = price;
                this.packsList[index].totalValue += parseFloat(price) * this.packsList[index].cryptocurrencies[i].owned;
              })
          }
        });
      })
    },
    selectPack(idPack,cryptocurrencies) {
      this.selectedPack = idPack;
      this.cryptocurrenciesPackSelected = cryptocurrencies;
    },
    selectCrypto(idCrypto) {
      this.selectedCrypto = idCrypto;
    }
  },

};
</script>

<style>
</style>
