<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <p>¿Estás seguro que deseas eliminar el Pack?</p>
    <button class="btn btn-outline-secondary float-left mt-4" data-dismiss="modal" aria-label="Close">Cancelar</button>
    <button class="btn btn-primary float-right mt-4" data-dismiss="modal" aria-label="Close" @click="confirm()">
      <span :class="{ 'visible': !loading, 'invisible': loading}">Confirm</span>
      <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
    </button>
  </Modal>
</template>

<script>

import Modal from '../../../Shared/Modal.vue';
import axios from 'axios';
export default {
  name: 'DeletePack',
  props: [
    'id',
    'modalTitle',
    'class',
    'idPack',
  ],
  data() {
    return {
      classes: this.class,
      loading: false
    }
  },
  components: {
    Modal
  },
  methods: {
    confirm(){
      this.loading = true;
      axios.delete(`/cryptos/packs/delete?id_pack=${this.idPack}`)
        .then(()=> {
          this.loading = false;
          this.closeModal();
          this.$emit('packDeleted');
        })
    },
    closeModal(){
      document.querySelector('#DeletePack .close').click();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
